import { useMsal, useAccount } from "@azure/msal-react";
import { useEffect, useState } from 'react';

const scope = process.env.REACT_APP_SCOPE;

export const useToken = () => {

    const { accounts, instance } = useMsal();
    const account = useAccount(accounts[0] || {})
    const [tokenRequest, setTokeReq] = useState({
        scopes: [scope],
        account: account
    });

    useEffect(() => {
        setTokeReq(c => ({ ...c, account }))
    }, [account]);

    const getTokenSilent = async() => {
        const {accessToken} = await instance.acquireTokenSilent(tokenRequest)

        return accessToken
    }

    return {
        tokenRequest,
        getTokenSilent
    }

}
