export const agentsType = {
    getAgents: 'Obtener agentes',
    addAgents: 'Obtener mas agentes',
    clearAgents: 'Limpiar agentes',
    selectIdAgent: 'Select id agent',
    loadingAgents: 'Cargando Agents',
    agentCurrentChatRoom: 'Agent Current ChatRoom',
    agentCloseCurrentChatRoom: 'Agent Close Current ChatRoom',


}