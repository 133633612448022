import React ,{ createContext } from "react";
import { useSocket } from "../hooks/useSocket";
// Crea el contexto del socket
export const SocketContext = createContext();

// Define el componente proveedor del socket
export const SocketProvider = ({ children }) => {
    // Obtiene el socket utilizando el hook useSocket y la URL del socket de la variable de entorno REACT_APP_SKYCOMMS_SOCKET
    const { socket } = useSocket(process.env.REACT_APP_SKYCOMMS_SOCKET);

    return (
        // Proporciona el socket a través del contexto SocketContext a los componentes hijos
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
