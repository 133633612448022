// reducers.js
import { types} from '../../types/types';

const initialState = {
  snackbarOpen: false,
  snackbarMessage: '',
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      console.log("notificationReducer::SHOW_SNACKBAR",action)
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload,
      };
    case types.HIDE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: '',
      };
    default:
      return state;
  }
};