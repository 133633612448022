/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useRef, useState} from "react";
import {io} from "socket.io-client";
import {useSelector, useDispatch} from "react-redux";
import {client} from "../axios/client";
import {
    messagesNewMessage,
    messageUnreadMessage,
    messageAppendOffsetByMessageId,
    messageAddNotification,
    messageRemoveNotification,
    messageReadMessage,
    messageClearSelectContactMessages,
    messagesSetSelectContactMessage,
    messagesLoading,
    getContactMessagesByIdContact,
    messageAddFirstContactMessages,
} from "../actions/messages";
import {useToken} from "./useToken";
import {
    addContact,
    moveContact,
    removeContact,
    updateContact,
    markContact,
} from "../actions/assignedContacts";
import {selectIdAgent} from "../actions/agentes";
import {
    setSelectedContact,
    contactClear, setSavedContactDetails,
} from "../actions/contacts";
import {useMsal} from "@azure/msal-react";
import axios from "axios";
import {showSnackbar} from "../actions/snackbar";


export const useSocket = (path) => {
    const socket = useMemo(() => io(path, {
        reconnection: true,
        autoConnect: true,
        reconnectionAttempts: 2, // Limita el número de intentos de reconexion
        reconnectionDelay: 10000,
    }), [path]);
    // URL del sonido a reproducir para las notificaciones
    const soundUrl = process.env.REACT_APP_SOUND_URL;

    // Dispatch para realizar acciones en Redux
    const dispatch = useDispatch();

    // Obtener el número de teléfono del agente del estado de Redux
    const {phone} = useSelector((state) => state.agent);
    const agentCurrentChatRoom = useSelector((state) => state.agentes.agentCurrentChatRoom);


    // Obtener los mensajes de los contactos del estado de Redux
    const {contactsMessages} = useSelector((state) => state.messages);

    // Obtener los contactos asignados del estado de Redux
    const {contactsAssigneds} = useSelector((state) => state.assignedContact);

    // Obtener los mensajes del contacto seleccionado del estado de Redux
    const {selectContactMessages} = useSelector((state) => state.messages);

    // Obtener los detalles del contacto del estado de Redux
    const {contactDetails, savedContactDetails} = useSelector((state) => state.contact);

    // Obtener el ID del agente del estado de Redux
    const _id = useSelector((state) => state.agent._id);

    // Obtener la función getTokenSilent del hook useToken
    const {getTokenSilent} = useToken();
    const CancelToken = axios.CancelToken;

    const source = CancelToken.source();

    // Referencias para almacenar valores previos
    const refContactMessages = useRef(contactsMessages);
    const refAssignedAgentContacts = useRef(contactsAssigneds);
    const refSelectContactMessages = useRef(selectContactMessages);
    const refContactDetails = useRef(contactDetails);
    const refIdMyAgent = useRef(_id);
    const {instance} = useMsal()
    const {tokenRequest} = useToken()

    // Estado para almacenar la disponibilidad en línea
    const [online, setOnline] = useState(false);

    // Actualiza la referencia del ID del agente cuando cambia
    useEffect(() => {
        refIdMyAgent.current = _id;
    }, [_id]);

    // Actualiza la referencia de los mensajes del contacto seleccionado cuando cambia
    useEffect(() => {
        refSelectContactMessages.current = selectContactMessages;
    }, [selectContactMessages]);

    // Actualiza la referencia de los detalles del contacto cuando cambia
    useEffect(() => {
        refContactDetails.current = contactDetails;
    }, [contactDetails]);

    // Actualiza la referencia de los mensajes de los contactos cuando cambia
    useEffect(() => {
        refContactMessages.current = contactsMessages;
    }, [contactsMessages]);

    // Actualiza la referencia de los contactos asignados cuando cambia
    useEffect(() => {
        refAssignedAgentContacts.current = contactsAssigneds;
    }, [contactsAssigneds]);

    // Configura el evento 'connect' del socket
    useEffect(() => {
        socket.on('connect', () => {
            setOnline(socket.connected);
            console.log('Connect:', socket.connected);
            if (phone && socket.connected) {
                console.log('reconnection_join_room', phone, socket.connected);
                socket.emit('join_room', phone.toString());
            }
        });
    }, [socket, phone]);

    // Maneja la conexión del socket cuando el número de teléfono cambia
    useEffect(() => {
        if (phone && socket.connected) {
            console.log("join_room: ", phone, socket.connected);
            socket.emit('join_room', phone.toString());
        }
    }, [phone]);

    // Configura el evento 'new_message' del socket
    useEffect(() => {
        socket.on('new_message', (message) => {
            console.log("new_message:", message);
            if (message.incoming === true || message.incoming === false) {
                contactAgentAssigned(message);
                if (message.incoming === true) {
                    try {
                        // Obtiene el contacto del mensaje si se encuentra descargado
                        const audio = new Audio(soundUrl);
                        audio.play().catch(function (error) {
                            console.log("Chrome cannot play sound without user interaction first");
                        });
                    } catch (e) {
                        console.log('Error al reproducir el audio:', e);
                    }
                    dispatch(showSnackbar('Tienes nuevos mensajes'));
                }
            }
        });
        return () => {
            socket.off('new_message');
        };
    }, [socket]);


    // Configura el evento 'update_contact' del socket
    useEffect(() => {
        socket.on('update_contact', (contact) => {
            // console.log("contact:", contact);
            updateContactReceived(contact, refAssignedAgentContacts.current);
        });
    }, [socket]);

    // Configura el evento 'error' del socket
    useEffect(() => {
        socket.on("error", (err) => {
            console.log("err: ", err);
        });
    }, [socket]);

    // Configura el evento 'connect_error' del socket
    useEffect(() => {
        socket.on("connect_error", (error) => {
            console.log("socket errors : ");
            console.log(error);
        });
    }, [socket]);

    /**
     * Función para manejar la actualización de un contacto recibido
     * @param {object} contact - El contacto que se ha recibido
     * @param {array} listcontacts - La lista de contactos existente
     */
    const updateContactReceived = (contact, listcontacts) => {
        console.log("updateContactReceived::contact", contact)
        console.log("updateContactReceived::listcontacts", listcontacts)
        // Combina todos los contactos asignados en una sola lista
        let contacts = refAssignedAgentContacts.current.reduce((prevAgent, currentAgent) => {
            if (!Array.isArray(prevAgent)) {
                return prevAgent.contacts.concat(currentAgent.contacts);
            }
            return prevAgent.concat(currentAgent.contacts);
        });

        // Si solo hay un agente asignado, se utiliza su lista de contactos
        if (refAssignedAgentContacts.current.length === 1)
            contacts = refAssignedAgentContacts.current[0].contacts;

        // Encuentra el contacto local en la lista de contactos
        const localContact = contacts.find(contactEval => contactEval._id === contact._id);

        let localAgent;
        if (localContact)
            localAgent = refAssignedAgentContacts.current.find(agentContact => agentContact._id === localContact.id_agent);

        // Encuentra el agente del contacto recibido
        const contactAgent = refAssignedAgentContacts.current.find(agentContact => agentContact._id === contact.id_agent);

        // Si el contacto local existe pero no está asignado al agente del contacto recibido, se elimina
        if (localContact && !contactAgent) {
            dispatch(removeContact(localContact));
            return;
        }

        // Si el contacto local no existe pero está asignado al agente del contacto recibido, se agrega
        if (!localContact && contactAgent) {
            dispatch(addContact(contact));
            return;
        }

        // Si el contacto local está asignado al mismo agente que el contacto recibido, se actualiza
        console.log("localContact", localContact)
        console.log("contact", contact)
        if (localContact && localContact.id_agent === contact.id_agent) {
            dispatch(updateContact(contact));
            return;
        }

        // Si el contacto local está asignado a un agente diferente al contacto recibido y ambos agentes existen,
        // se realiza la transferencia del contacto
        if (localContact && localContact.id_agent !== contact.id_agent && localAgent && contactAgent) {
            // Si el contacto recibido es el contacto actualmente seleccionado, se realiza la limpieza
            if (contact._id === refContactDetails.current._id) {
                dispatch(contactClear());
                dispatch(messageClearSelectContactMessages());
            }

            // Se mueve el contacto de un agente a otro
            dispatch(moveContact({
                id_contact: contact._id,
                id_agent_source: localContact.id_agent,
                id_agent_target: contact.id_agent
            }));
            return;
        }
    }

    /**
     * Función para manejar la asignación de agente a un contacto
     * @param {object} message - El mensaje que contiene la información del contacto
     */
    const contactAgentAssigned = (message) => {
        console.log("contactAgentAssigned::refContactDetails",refContactDetails)
        console.log("contactAgentAssigned::message",message)
        console.log("contactAgentAssigned::refSelectContactMessages",refSelectContactMessages,Object.keys(refSelectContactMessages.current).length !== 0)
        if(Object.keys(refSelectContactMessages.current).length !== 0){
            if (message.id_contact === refContactDetails.current._id) {
                if (message.contact.hasOwnProperty("id_agent") === false || message.contact.id_agent === null || message.id_agent !== refIdMyAgent.current) {
                    handleMessageActions(message.contact, message);
                }
            }
            console.log("contactAgentAssigned::refAssignedAgentContacts", refAssignedAgentContacts)

            handleAgentMove(message.contact, refAssignedAgentContacts.current);
        }
        dispatch(updateContact(message.contact));
    }

    /**
     * Función para manejar el movimiento de agente para un contacto
     * @param {object} contact - El contacto al que se aplica el movimiento de agente
     * @param {object} contactsAssigneds - El contacto al que se aplica el movimiento de agente
     */
    const handleAgentMove = (contact, contactsAssigneds) => {
        console.log("handleAgentMove::contact", contact)
        console.log("handleAgentMove::contactsAssigneds", contactsAssigneds)
        let agent = undefined;
        if (contactsAssigneds.length === 1) {
            agent = contactsAssigneds[0]
        } else {
            agent = contactsAssigneds.find(agentContact => agentContact._id === contact.id_agent);
        }
        console.log("handleAgentMove::agent", agent)

        const contacts = contactsAssigneds.flatMap((agentContact) => agentContact.contacts);
        console.log("handleAgentMove::contacts", contacts)

        let inListContact = contacts.find((agentContact) => agentContact._id === contact._id);

        console.log("handleAgentMove::inListContact", inListContact)

        if (!agent && !inListContact) {
            console.log("!agent && !inListContact", true)
            return;
        }

        if (agent && !inListContact) {
            console.log("agent && !inListContact", true)
            const newContact = {...contact, id_agent: null, hasMark: true};
            dispatch(addContact(newContact));
            inListContact = newContact;

        }

        if (inListContact.id_agent === contact.id_agent && agent) {
            console.log("inListContact.id_agent === contact.id_agent && agent", true)
            dispatch(updateContact(contact));
            handleMarkContact(inListContact, contact, agent);
            return
        }

        if (inListContact.id_agent === null && inListContact.id_agent !== contact.id_agent && !agent) {
            console.log("inListContact.id_agent !== contact.id_agent && !agent", true)
            dispatch(removeContact(inListContact));
        }

        if (inListContact.id_agent !== contact.id_agent && agent) {
            console.log("inListContact.id_agent !== contact.id_agent && agent", true)
            dispatch(moveContact({
                id_contact: contact._id,
                id_agent_source: inListContact.id_agent,
                id_agent_target: contact.id_agent,
            }));
            handleMarkContact(inListContact, contact, agent);
        }
        dispatch(updateContact(contact));
        dispatch(setSavedContactDetails(contact))

        handleMoveAndLoadAgent(inListContact, contact);
    };

    /**
     * Función para manejar el movimiento de un contacto y cargar el agente asignado
     * @param {object} currentContact - El contacto actual antes de la actualización
     * @param {object} updatedContact - El contacto actualizado
     */

    const handleMoveAndLoadAgent = (currentContact, updatedContact) => {
        console.log("handleMoveAndLoadAgent::currentContact",currentContact)
        console.log("handleMoveAndLoadAgent::updatedContact",updatedContact)
        // Si el contacto actual tenía una marca, se elimina la marca utilizando la acción markContact
        if (currentContact.hasMark) {
            dispatch(markContact({
                contact: currentContact,
                hasMark: false
            }));
        }

        // Si el agente asignado al contacto actualizado es el mismo que el agente actual, se selecciona el contacto utilizando setSelectedContact y selectIdAgent
        if (updatedContact.id_agent === refIdMyAgent.current) {
            dispatch(setSelectedContact(updatedContact));
            dispatch(selectIdAgent(refIdMyAgent.current));
            return;
        }

        // Si el contacto actualizado es el mismo que el contacto en los detalles de contacto actuales,
        // se borran los detalles de contacto y los mensajes seleccionados utilizando contactClear y messageClearSelectContactMessages
        // if (updatedContact._id === refContactDetails.current._id) {
        //     console.log("updatedContact._id === refContactDetails.current._id",updatedContact._id === refContactDetails.current._id)
        //     dispatch(contactClear());
        //     dispatch(messageClearSelectContactMessages());
        // }
    }

    /**
     * Función para manejar la marca de un contacto
     * @param {object} currentContact - El contacto actual antes de la actualización
     * @param {object} updatedContact - El contacto actualizado
     * @param {object} agent - El agente asignado al contacto actualizado
     */
    const handleMarkContact = (currentContact, updatedContact, agent) => {

        // Si el contacto actual no tiene la propiedad 'hasMark', se retorna
        if (!('hasMark' in currentContact)) return;
        // Si el contacto actual no tiene marca, se retorna
        if (!currentContact.hasMark) return;

        // Si el contacto actualizado no es el mismo que el contacto en los detalles de contacto actuales
        // y el contacto actual tiene una marca, se elimina la marca utilizando la acción markContact
        if (updatedContact._id !== refContactDetails.current._id && currentContact.hasMark) {
            dispatch(markContact({
                contact: currentContact,
                hasMark: false
            }));
            return;
        }

        // Si el agente asignado al contacto actualizado es el mismo que el agente actual,
        // se selecciona el contacto utilizando setSelectedContact y selectIdAgent
        if (updatedContact.id_agent === refIdMyAgent.current) {
            dispatch(setSelectedContact(updatedContact));
            dispatch(selectIdAgent(agent._id));
        }

        // Se elimina la marca del contacto utilizando la acción markContact
        dispatch(markContact({
            contact: currentContact,
            hasMark: false
        }));
    }


    /**
     * Función para manejar las acciones relacionadas con los mensajes entrantes y salientes
     * @param {object} contact - El contacto asociado al mensaje
     * @param {object} message - El mensaje recibido
     */
    const handleMessageActions = (contact, message) => {
        // Obtiene la referencia si hay mensajes descargados del contacto en el mensaje entrante
        console.log("handleMessageActions::contact", contact)
        console.log("handleMessageActions::message", message)
        console.log("handleMessageActions::refContactMessages", refContactMessages)
        console.log("handleMessageActions::refSelectContactMessages", refSelectContactMessages)
        let contactMessages = refContactMessages.current.find(msg => msg.id_contact === message.id_contact);
        let requestContactMessages = false
        let executeIncomeMessageProcess = false
        // Verifica si hay mensajes descargados del contacto en el mensaje entrante
        console.log("handleMessageActions::contactMessages_after", contactMessages)
        if (!contactMessages && refSelectContactMessages) {
            contactMessages = refSelectContactMessages.current
            dispatch(messageAddFirstContactMessages(contactMessages));
        }
        console.log("handleMessageActions::contactMessages_before", contactMessages)
        if (contactMessages) {
            // Actualiza el estado de mensajes no leídos si el mensaje entrante es de un contacto sin mensajes no leídos
            if (contact.unread_message === 0 && message.incoming) {
                dispatch(messageUnreadMessage(contact._id));
            }
            message._id = message.id
            // Marca el mensaje como leído si es saliente
            if (!message.incoming) {
            }
            // Agrega el mensaje a la lista de mensajes del contacto y actualiza el offset
            console.log("typeof contactMessages", typeof contactMessages)
            console.log("Array.isArray(contactsMessages)", Array.isArray(contactsMessages))
            if (contactMessages.id_contact === message.id_contact) {
                executeIncomeMessageProcess = true
            } else {
                requestContactMessages = true
            }
            if (executeIncomeMessageProcess) {
                dispatch(messageAppendOffsetByMessageId(message.id_contact, message._id));
                dispatch(messagesNewMessage(message));
                // dispatch(messagesSetSelectContactMessage(contactMessage))
            }
        } else {
            requestContactMessages = true
        }
        console.log("useSocket::requestContactMessages", requestContactMessages)
        if (requestContactMessages === true) {
            dispatch(messagesLoading(true))
            instance.acquireTokenSilent(tokenRequest)
                .then(({accessToken}) => {
                    dispatch(getContactMessagesByIdContact(message.id_contact, accessToken, source, contact.unread_message));
                });
            dispatch(messagesLoading(false))
        }
        // Remueve la notificación del contacto si el mensaje no es entrante
        if (!message.incoming) {
            dispatch(messageRemoveNotification(contact._id));
            dispatch(messageReadMessage(contact._id));

        }
        // Agrega una notificación al contacto si el mensaje es entrante
        if (message.incoming) {
            dispatch(messageAddNotification(contact));
        }
        if (message) {
            console.log("processed");
        }
    }

    return {
        socket,
        online
    };

}
