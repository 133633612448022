import { types } from "../types/types";


export const showSnackbar = (message) => ({
  type: types.SHOW_SNACKBAR,
  payload: message,
});

export const hideSnackbar = () => ({
  type: types.HIDE_SNACKBAR,
});
