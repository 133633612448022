import React from "react";
import { reactPlugin } from "./appInsights";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import Logo from "./assets/images/logos/logo-skycomm.png";

const ErrorFallback = () => (
  <div style={{ textAlign: "center" }}>
    <img src={Logo} alt="Error" style={{ maxWidth: "100%", maxHeight: "100%" }} />
    <h1>Ha ocurrido un error</h1>
    <p>Por favor, antes de refrescar con F5, envíenos un mensaje.</p>
  </div>
);

const AppInsightsContextProvider = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={ErrorFallback}
        appInsights={reactPlugin}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export { AppInsightsContextProvider };
