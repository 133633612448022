import { types } from "../../types/types";

const INITIAL_STATE = {
    groups:[],
    groupDetails:{},
    count:0,
    loading: false
}


export const groupsReducer = (state = INITIAL_STATE, action) => {

    const { type, payload } = action;

    switch (type) {

        case types.groupsGet:{
            return{
                ...state,
                groups:[...payload.items],
                count:payload.count,
                loading: payload.loading

            }
        }
        case types.groupsNew:{
            return{
                ...state

            }
        }

        case types.groupsSetSelected: {
            return{
                ...state,
                groupDetails:payload.details
            }
        }

        default:
             return state;

    }


}
