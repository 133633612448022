import React from 'react';
import {useRoutes} from 'react-router-dom';
import {CssBaseline, ThemeProvider, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {SocketProvider} from './context/SocketContext';
import {getListContacts} from "./actions/contacts";
import {useMsal} from "@azure/msal-react";
import {useToken} from "./hooks/useToken";
import {useDispatch, useSelector} from "react-redux";

const App = () => {
    const routing = useRoutes(Router);
    const theme = ThemeSettings();
    const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);
    const [showOfflineSnackbar, setShowOfflineSnackbar] = React.useState(false);
    const [showOnlineSnackbar, setShowOnlineSnackbar] = React.useState(false);
    const [isFocused, setIsFocused] = React.useState(document.hasFocus());
    const {instance} = useMsal()
    const {tokenRequest} = useToken()
    const dispatch = useDispatch();
    const {id_customer} = useSelector(state => state.agent);


    React.useEffect(() => {
        function handleOnline() {
            setIsOnline(true);
            setShowOnlineSnackbar(true);
            setTimeout(() => setShowOnlineSnackbar(false), 3000); // Ocultar Snackbar después de 3 segundos
            window.location.href = "/admin/chat"; // Recargar la página cuando se restablezca la conexión
        }

        function handleOffline() {
            setIsOnline(false);
            setShowOfflineSnackbar(true);
        }

        function handleVisibilityChange() {
            setIsFocused(document.hasFocus());
        }

        function handleFocus() {
            setIsFocused(true);
        }

        function handleBlur() {
            setIsFocused(false);
        }

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    const handleCloseOfflineSnackbar = () => {
        setShowOfflineSnackbar(false);
    };

    const handleCloseOnlineSnackbar = () => {
        setShowOnlineSnackbar(false);
    };
    return (
        <SocketProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {routing}
                <Snackbar
                    open={showOfflineSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseOfflineSnackbar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="error"
                        onClose={handleCloseOfflineSnackbar}
                    >
                        ¡Se ha perdido la conexión a Internet!
                    </MuiAlert>
                </Snackbar>
                <Snackbar
                    open={showOnlineSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseOnlineSnackbar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="success"
                        onClose={handleCloseOnlineSnackbar}
                    >
                        ¡La conexión a Internet se ha restablecido! La página se recargará automáticamente.
                    </MuiAlert>
                </Snackbar>
            </ThemeProvider>
        </SocketProvider>
    );
};

export default App;
