import { types } from "../../types/types";

export const agentReducer = (state = {}, action) => {

    switch(action.type){

        case types.agentUpdate:

            return {
                ...action.payload
            };
        case types.agentClear:

            return {};
        
        case types.agentGet:

            return state;

        default:
            return state;

    }

}

