import {client} from '../axios/client';
import {agentsType} from '../reducers/agents/agentsTypes';
import {types} from "../types/types";

export const getAgentsFromApi = (
    id_customer,
    token,
    offset = 0,
    limit = 20
) => {
    return (dispatch) => {
        client.get(`/agents/list/${id_customer}`,
            {
                params: {
                    offset,
                    limit,
                },
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(({data}) => {
                const {items, count} = data;
                const customerAgents = {
                    offset,
                    limit,
                    items: items,
                    count
                };
                if (offset === 0){dispatch(getAgents(customerAgents));
                }else{
                    dispatch(addAgents(customerAgents))
                }
            })
    }
}

export const getAgents = (agentes) => ({
    type: agentsType.getAgents,
    payload: agentes
});

export const addAgents = (agentes) => ({
    type: agentsType.addAgents,
    payload: agentes
});


export const loadingAgents = (flag) => ({
    type: agentsType.getAgents,
    payload: flag
});

export const selectIdAgent = (id_agent) => ({
    type: agentsType.selectIdAgent,
    payload: id_agent
})

export const currentChatRoomAgent = (room,agent,contact)=> (
    {
        type:agentsType.agentCurrentChatRoom,
        payload:{room:room,agent:agent,contact:contact}
    }
);
export const closeCurrentChatRoomAgent = ()=> (
    {
        type:agentsType.agentCloseCurrentChatRoom,
        payload:null
    }
);