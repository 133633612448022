import { types } from "../../types/types";


const INITIAL_STATE = {
    templates:[]
}

export const templatesReducer = (state = INITIAL_STATE, action) =>{

    const {type, payload} = action;

    switch(type){

        case types.templatesGet:

            return{
                ...state,
                templates:payload.templates
            }
            
        

        default:
            return state;
    }
}