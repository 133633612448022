import { types } from "../../types/types";

const INITIAL_STATE = {
    tags: [],
    loading: false,
    
}

export const tagsReducer = (state = INITIAL_STATE, action) => {

    const { type, payload } = action;

    switch (type) {
        case types.tagsGet:
            
                return {
                    
                    ...state,
                    tags: [
                        ...state.tags,
                        ...payload.tags
                    ],
                    loading: payload.loading,
                    
                };
        case types.tagsUpdate:
            return{
                ...state,
                tags: [
                    ...state.tags,
                    ...payload.tags
                ]
            }
           
            
            
    
        default:
            return state;
    }

}