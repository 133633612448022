import { client } from '../axios/client';
import { assignedTypes } from '../reducers/contacts/assignedTypes';

export const asyncAssignedContactFetch = (
    id_customer,
    token,
    offset = 0,
    id_agent = null,
    limit = 20
) => {

    return (dispatch) => {
        client.get(`/contacts/${id_customer}`, {
            params: {
                offset,
                limit,
                id_agent
            },
            headers: {'Authorization' : `Bearer ${token}`}
        })
        .then( ({data}) =>{
            console.log(data)
            const {items, count} = data;
            const contactAssigned = {
                _id: id_agent,
                offset,
                limit,
                contacts: items,
                count
            };
            dispatch(getContacts(contactAssigned));
            dispatch(loadingContacts(false));
        })
    }
}

export const getContacts = (contactAssigned) => ({
    type: assignedTypes.getContacts,
    payload: contactAssigned
}); 

export const loadingContacts = (flag) => ({
    type: assignedTypes.loadingContacts,
    payload: flag
});

export const moveContact = (payload) => ({
    type: assignedTypes.moveContact,
    payload
});

export const clearIdContact = () => ({
    type: assignedTypes.clearIdContact
});

export const addContact = (contact) => ({
    type: assignedTypes.addContact,
    payload: contact
});

export const updateContact = (contact) => ({
    type: assignedTypes.updateContact,
    payload: contact
});

export const removeContact = (contact) => ({
    type: assignedTypes.removeContact,
    payload: contact
});

export const markContact = (payload) => ({
    type: assignedTypes.markContact,
    payload
});