import { TableBody } from '@mui/material';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { element, exact } from 'prop-types';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Unauthorized = Loadable(lazy(() => import('../views/authentication/Unauthorized')));

/* ****Pages***** */
const Home = Loadable(lazy(() => import('../views/dashboards/Home')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const TableHeadContacts = Loadable(lazy( () => import('../views/contacts/TableHeadFilter') ));
const Chat = Loadable(lazy(() => import('../views/chat/Chat')));
const ContactsDetails = Loadable(lazy(() => import('../views/contacts/ContactsDetails')));
const Groups = Loadable(lazy(() => import('../views/groups/Groups')));
const Filter = Loadable(lazy(() => import('../views/groups/AdvancedFilters')));
const Templates = Loadable(lazy(() => import('../views/templates/Templates')));
const Categories = Loadable(lazy(() => import('../views/categories/Categories')));


const Router = [
    {
        path: '/',
        element: <FullLayout />,
        children: [
            { path: '/', element: <Navigate to="/auth/login" /> },
            { path: '/admin/home', exact: true, element: <Navigate to="/admin/chat" /> },
            { path: '/admin/chat', exact: true, element: <Chat /> },
            { path: '/admin/contacts', element: <TableHeadContacts/> },

            { path: '/admin/contacts/:contactId', exact: true, element: <ContactsDetails /> },
            { path: '/admin/groups', exact: true, element: <Groups /> },
            { path: '/admin/groups/newGroup', exact: true, element: <Filter /> },
            { path: '/admin/templates', exact: true, element: <Templates /> },
            { path: '/admin/categories', exact: true, element: <Categories/>},
            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },
    {
        path: 'auth',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <Error /> },
            { path: 'login', element: <Login /> },
            { path: 'unauthorized', element: <Unauthorized /> },
            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },
];

export default Router;
