const initialState = {
  contactCategories: [],
  modalOpen: false,
};

const contactCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTACT_CATEGORIES":
      return {
        ...state,
        contactCategories: action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        modalOpen: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
};

export default contactCategoryReducer;
