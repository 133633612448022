import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import CustomizerReducer from '../redux/customizer/CustomizerReducer';
import { messagesReducer } from '../reducers/messages/messagesReducer';
import { agentReducer } from '../reducers/agents/agentReducer';
import { contactsReducer } from '../reducers/contacts/contactsReducer';
import { tagsReducer } from '../reducers/tags/tagsReducer';
import {groupsReducer} from '../reducers/groups/groupsReducer';
import { assignedContactsReducer } from '../reducers/contacts/assignedContactsReducer';
import { agentesReducer } from '../reducers/agents/agentsReducer';
import { templatesReducer } from '../reducers/templates/templatesReducer';
import {notificationReducer} from "../reducers/notifications/notificationReducer";
import categoryReducer from '../reducers/categories/categoryReducer';
import contactCategoryReducer from '../reducers/contactsCategories/contactCategoriesReducer';

const reducers = combineReducers({
    messages: messagesReducer,
    agent: agentReducer,
    contact: contactsReducer,
    tags: tagsReducer,
    groups: groupsReducer,
    assignedContact: assignedContactsReducer,
    agentes: agentesReducer,
    templates:templatesReducer,
    notifications:notificationReducer,
    CustomizerReducer,
    categories: categoryReducer,
    contactCategory: contactCategoryReducer
});

export const Store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
);