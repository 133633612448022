// Importa el tipo de acciones agentsType desde './agentsTypes'
import {agentsType} from './agentsTypes';

// Define el estado inicial del reducer
const initialState = {
    /**
     * Lista de agentes
     * @type {Array}
     */
    agents: [],

    /**
     * Agentes del cliente
     * @type {null|object}
     */
    customerAgents: null,

    /**
     * Agente seleccionado
     * @type {null|object}
     */
    agentSelected: null,
    /**
     * Actual Chat room del agente
     * @type {null|object}
     */
    agentCurrentChatRoom: null,
    /**
     * Indicador de carga de agentes
     * @type {boolean}
     */
    loading_agents: false,
};

/**
 * Reducer de agentes
 * @param {object} state - Estado actual
 * @param {object} action - Acción a ejecutar
 * @returns {object} - Nuevo estado
 */
export const agentesReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case agentsType.getAgents: {
            // Actualiza la lista de agentes con los datos recibidos en el payload
            return {
                ...state,
                agents: payload,
            };
        }
        case agentsType.addAgents: {
            const {offset, limit, items, count} = payload;
            const current_agents = state.agents;
            const array = current_agents.items.concat(items);
            const sortedArray = array.sort((a, b) => {
                const indexA = array.indexOf(a);
                const indexB = array.indexOf(b);
                return indexA - indexB;
            });
            const seenIds = {};
            const uniqueArray = array.filter((item) => {
                if (!seenIds[item._id]) {
                    seenIds[item._id] = true;
                    return true;
                }
                return false;
            });
            const contactMessages = {
                items: uniqueArray,
                count,
                offset,
                limit,
            };
            // Actualiza la lista de agentes con los datos procesados en el payload
            return {
                ...state,
                agents: contactMessages,
            };
        }
        case agentsType.selectIdAgent: {
            // Actualiza el agente seleccionado con el valor recibido en el payload
            return {
                ...state,
                agentSelected: payload,
            };
        }
        case agentsType.loadingAgents: {
            // Actualiza el indicador de carga de agentes con el valor recibido en el payload
            return {
                ...state,
                loading_contacts: payload,
            };
        }
        case agentsType.agentCurrentChatRoom: {
            // Actualiza el indicador de carga de agentes con el valor recibido en el payload
            return {
                ...state,
                agentCurrentChatRoom: payload,
            };
        }
        case agentsType.agentCloseCurrentChatRoom: {
            // Actualiza el indicador de carga de agentes con el valor recibido en el payload
            return {
                ...state,
                agentCurrentChatRoom: null,
            };
        }
        default:
            return state;
    }
};
